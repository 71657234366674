// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  client: 'climatecare-ecommerce',
  production: false,
  stripePublishableKey: 'pk_test_AKFzXNrTAU4SAL1NTUimhbl3',
  stripePublishableKeyTest : '%stripeTestClientId%',
  paypalClientId: 'ASWolWWmcWqVsTLFo4wdwlURxKGrXJYsWlWXoPzNzkza6apKHo_MkCb65jKoUpWornyGqqGUZTog2g7A',
  paypalClientIdTest: '%paypalTestClientId%',
  siteUrl: 'http://dev-climatecare-ecommerce.co2analytics.com/',
  defaultLanguage: 'en',
  minimumBaseCurrencyAmount: 0.75,
  txOrigins: [
    '*'
  ],
  rxOrigins: [
    '*'
  ],
  partnerConfig: {
    partnerKey: 'b6d09d9b-852e-466a-bbfe-de692fd4bdc4',
    domain: 'climatecare.org'
  },
  apis: {
    domain: 'https://dev-srvc-calculators.co2analytics.com',
    fx: 'https://dev-srvc-ecomm.co2analytics.com/api/v1',
    fin: 'https://dev-srvc-ecomm.co2analytics.com/api/v1',
    version: 'v1.0.0'
  },
  bypassCode: '%bypassCode%',
  componentHierarchy: {
    headerComponents: [
      { templateName: 'calculatorHeaderComponent', componentName: 'CalculatorHeaderComponent' }
    ],
    featureComponents: [
      {
        name: 'freight',
        seqn: '8',
        title: 'Freight',
        theme: {
          fillColor: '#C9DDE8',
          textClass: 'calculator-selector-text-dark'
        },
        component: { templateName: 'freightComponent', componentName: 'ClimateCareFreightComponent' }
      }
    ],
    global: [
      {
        component: { defaultFeatureIndex: 0 }
      },
      {
        component: { templateName: 'offsetProjectsComponent', componentName: 'OffsetProjectsComponent' }
      },
      {
        component: { templateName: 'cartComponent', componentName: 'CartComponent' }
      },
      {
        component: { templateName: 'customerDetailsComponent', componentName: 'CustomerDetailsComponent' }
      },
      {
        component: { templateName: 'payOffsetsComponent', componentName: 'PayOffsetsComponent' }
      },
      {
        component: { templateName: 'confirmationComponent', componentName: 'ConfirmationComponent' }
      }
    ],
    footerComponents: [
      { templateName: 'icroaFooterComponent', componentName: 'IcroaFooterComponent' }
    ]
  }
}

// For easier debugging in development mode, you can import the following file
// to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//
// This import should be commented out in production mode because it will have a negative impact
// on performance if an error is thrown.
//
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
